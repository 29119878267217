import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import app from './app/app.reducer';
import areas from './areas/areas.reducer';
import auth from './auth/auth.reducer';
import { LOGOUT_SUCCESS } from './auth/auth.types';
import bookings from './bookings/bookings.reducer';
import cart from './cart/cart.reducer';
import clubs from './clubs/clubs.reducer';
import init from './init/init.reducer';
import logAreas from './log-areas/log-areas.reducer';
import logs from './logs/logs.reducer';
import modal from './modal/modal.reducer';
import permits from './permits/permits.reducer';

const { routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});


const appReducer = combineReducers({
  router: routerReducer,
  auth,
  init,
  app,
  modal,
  areas,
  clubs,
  permits,
  cart,
  logs,
  logAreas,
  bookings,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
