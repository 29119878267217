import {
  all,
  call, put,
  select,
  take,
} from 'redux-saga/effects';
import authApi from '../../api/auth';
import usersApi from '../../api/users';
import modalConstants from '../../constants/modal.constants';
import routeConstants from '../../constants/route.constants';
import history from '../../router/history';
import message from '../../services/message.service';
import { getRefreshTokenObj, removeTokens, setTokens } from '../../services/token.service';
import { load, ready } from '../init/init.sagas';
import * as modalTypes from '../modal/modal.types';
import { getForgotPasswordEmail, getSignupData } from './auth.selectors';
import * as authTypes from './auth.types';

function* login() {
  while (true) {
    const { data } = yield take(authTypes.LOGIN_REQUEST);
    try {
      const { user, tokens } = yield call(authApi.login, data);
      yield call(setTokens, tokens);
      yield put({ type: authTypes.LOGIN_SUCCESS, user });
      if (!user.isEmailVerified) {
        // Display verify email modal
        yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.VERIFY_EMAIL });
      } else if (!user.address || !user.phone) {
        yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.COMPLETE_PROFILE });
      } else {
        yield put({ type: modalTypes.CLOSE_MODAL });
        message.success('Þú ert innskráð/ur');
      }
    } catch (error) {
      message.error(error.message);
      yield put({
        type: authTypes.LOGIN_FAILURE,
        error,
      });
    }
  }
}

function* register() {
  while (true) {
    const { data } = yield take(authTypes.REGISTER_REQUEST);
    try {
      const { signupData } = yield select(getSignupData);
      const { user, tokens } = yield call(authApi.register, { ...signupData, ...data });
      yield call(setTokens, tokens);
      yield put({ type: authTypes.REGISTER_SUCCESS, user });
      if (!user.isEmailVerified) {
        // Display verify email modal
        yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.VERIFY_EMAIL });
      }
    } catch (error) {
      message.error(error.message);
      yield put({
        type: authTypes.REGISTER_FAILURE,
        error,
      });
    }
  }
}

function* resendCode() {
  while (true) {
    yield take(authTypes.RESEND_VERIFY_EMAIL_CODE_REQUEST);
    try {
      yield call(authApi.resendCode);
      yield put({ type: authTypes.RESEND_VERIFY_EMAIL_CODE_SUCCESS });
      message.success('Kóði hefur verið endursendur á netfangið þitt', 'Póstur sendur');
    } catch (error) {
      message.error(error.message);
      yield put({ type: authTypes.RESEND_VERIFY_EMAIL_CODE_FAILURE, error });
    }
  }
}

function* verifyEmail() {
  while (true) {
    const { code } = yield take(authTypes.VERIFY_EMAIL_REQUEST);
    try {
      yield call(authApi.verifyEmail, code);
      yield put({ type: authTypes.VERIFY_EMAIL_SUCCESS });
      yield put({ type: authTypes.GET_ME_REQUEST });
      yield put({ type: modalTypes.CLOSE_MODAL });
      message.success('', 'Netfang hefur verið staðfest');
    } catch (error) {
      message.error(error.message);
      yield put({ type: authTypes.VERIFY_EMAIL_FAILURE, error });
    }
  }
}

function* forgotPassword() {
  while (true) {
    const { email } = yield take(authTypes.FORGOT_PASSWORD_REQUEST);
    try {
      yield call(authApi.forgotPassword, email);
      yield put({ type: authTypes.FORGOT_PASSWORD_SUCCESS });
      message.info(`Kóði hefur verið sendur á netfangið: ${email}`, 'Póstur sendur');
      yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.RESET_PASSWORD });
    } catch (error) {
      message.error(error.message);
      yield put({ type: authTypes.FORGOT_PASSWORD_FAILURE, error });
    }
  }
}

function* resetPassword() {
  while (true) {
    const { code, password } = yield take(authTypes.RESET_PASSWORD_REQUEST);
    try {
      const { email } = yield select(getForgotPasswordEmail);
      yield call(authApi.resetPassword, password, code);
      yield put({ type: authTypes.FORGOT_PASSWORD_SUCCESS });
      message.info(`Lykilorði hefur verið breytt fyrir netfangið: ${email}`);
      yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.LOGIN });
    } catch (error) {
      message.error(error.message);
      yield put({ type: authTypes.FORGOT_PASSWORD_FAILURE, error });
    }
  }
}

function* logout() {
  while (true) {
    yield take(authTypes.LOGOUT_REQUEST);
    try {
      yield call(load);
      const { token } = yield call(getRefreshTokenObj);
      yield call(authApi.logout, token);
      message.success('Þú hefur skráð þig út');
    } catch (error) {
      // Ignore
    } finally {
      yield put({ type: authTypes.LOGOUT_SUCCESS });
      yield call(removeTokens);

      history.push(routeConstants.HOME);
      yield call(ready);
    }
  }
}

function* getMe() {
  while (true) {
    yield take(authTypes.GET_ME_REQUEST);
    try {
      const user = yield call(usersApi.getMe);
      yield put({ type: authTypes.GET_ME_SUCCESS, user });
    } catch (error) {
      yield put({
        type: authTypes.GET_ME_FAILURE,
        error,
      });
    }
  }
}

function* updateMe() {
  while (true) {
    const action = yield take(authTypes.UPDATE_ME_REQUEST);
    const { user } = yield select((state) => state.auth);
    if (user) {
      try {
        const updatedUser = yield call(usersApi.updateMe, user.id, action.body);
        yield put({ type: authTypes.UPDATE_ME_SUCCESS, user: updatedUser });
        yield put({ type: modalTypes.CLOSE_MODAL });
      } catch (error) {
        yield put({ type: authTypes.UPDATE_ME_FAILURE, error });
      }
    }
  }
}

export default function* authSaga() {
  yield all([
    login(),
    register(),
    forgotPassword(),
    resetPassword(),
    resendCode(),
    verifyEmail(),
    getMe(),
    logout(),
    updateMe(),
  ]);
}
