import { all } from 'redux-saga/effects';

import appSaga from './app/app.sagas';
import areaSaga from './areas/areas.sagas';
import authSaga from './auth/auth.sagas';
import bookingsSaga from './bookings/bookings.sagas';
import clubSaga from './clubs/clubs.sagas';
import initSaga from './init/init.sagas';
import logAreasSaga from './log-areas/log-areas.sagas';
import logsSaga from './logs/logs.sagas';
import permitsSaga from './permits/permits.sagas';

export default function* rootSaga() {
  yield all([
    initSaga(),
    areaSaga(),
    authSaga(),
    appSaga(),
    clubSaga(),
    permitsSaga(),
    bookingsSaga(),
    logAreasSaga(),
    logsSaga(),
  ]);
}
