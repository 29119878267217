import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Map, MapView } from '../../../../components/ui';
import { mapLocation } from '../../../../utils/location';
import './areas.scss';

const HEADER_HEIGHT = 48 + 41;
const AreasMap = ({ visible }) => {
  const [selected, setSelected] = useState(null);
  const { areas, getAreasPending, getAreasError } = useSelector((state) => state.areas);

  if (getAreasPending) return null;
  if (areas.length === 0) return null;
  if (getAreasError) return null;
  return (
    <div className='map-container-wrapper'>
      <div className={`
    map
    ${visible && 'map-visible'}
  `}
      >
        <MapView>
          {areas && areas.map((area) => {
            const { lat, lng } = mapLocation(area.location);
            const isSelected = selected === area.id;
            return (
              <Map.Marker
                key={area.id}
                selected={isSelected}
                lat={lat}
                lng={lng}
                onClick={() => {
                  if (selected && selected === area.id) {
                    setSelected(null);
                  } else {
                    setSelected(area.id);
                  }
                }}
              >
                <Map.AreaInfo area={area} selected={isSelected} />
              </Map.Marker>
            );
          })}
        </MapView>
      </div>

    </div>
  );
};

AreasMap.propTypes = {
  visible: PropTypes.bool,
};

AreasMap.defaultProps = {
  visible: true,
};

export default AreasMap;
