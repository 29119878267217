import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const store = createStore(
  rootReducer,
  {},
  applyMiddleware(routerMiddleware, sagaMiddleware),
);

sagaMiddleware.run(rootSaga);
export default store;
